
.w-auto > .dropdown-menu  {
    width: auto !important;
}

.w-auto {
    width: auto !important;
}

.no-input-number-arrows input::-webkit-outer-spin-button, .no-input-number-arrows
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-input-number-arrows input[type=number] {
    -moz-appearance: textfield;
}

#temporada-selector > .dropdown-toggle > h2 {
    margin-bottom: 6px !important;
}

.col-xs-18,
.col-sm-18,
.col-md-18,
.col-lg-18 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-18 {
    width: 11%;
    float: left;
}
@media (min-width: 768px) {
    .col-sm-18 {
            width: 11%;
            float: left;
    }
}
@media (min-width: 992px) {
    .col-md-18 {
        width: 11%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-18 {
        width: 11%;
        float: left;
    }
}