//
// reboot.scss
//

body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    &.loading {
        visibility: hidden;
        opacity: 0;
    }
}


// Forms
label {
    font-weight: $label-font-weight;
}


button:focus {
    outline: none;
}